import React, { useState } from "react";
import { send } from "emailjs-com";
import LoadingSpinner from "./LoadingSpinner";
import "./Form.css";

function Form() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [toSend, setToSend] = useState({
    name: "",
    reply_to: "",
    answer: "",
    guest_names: "",
    comments: "",
  });

  const clearForm = () => {
    setToSend({
      name: "",
      reply_to: "",
      answer: "",
      guest_names: "",
      comments: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    send("service_ebd2su5", "template_cae9zqm", toSend, "2PPJhR_N0_MQXbpru")
      .then((response) => {
        setIsLoading(false);
        clearForm();
        setMessage("Thanks! All submitted!");
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="rsvp-form">
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your name"
            value={toSend.name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="reply_to"
            placeholder="Your e-mail"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <label htmlFor="answer">Can you make it?</label>
          <select name="answer" onChange={handleChange} defaultValue={toSend}>
            <option value="">--Please choose an option--</option>
            <option value={"I'll be there!"}>I'll be there!</option>
            <option value={"Sorry, can't make it "}>
              Sorry, can't make it
            </option>
          </select>
          <input
            type="text"
            name="guest_names"
            placeholder="Who's comin'? Guest name(s) please!"
            value={toSend.guest_names}
            onChange={handleChange}
          />{" "}
          <textarea
            type="text"
            name="comments"
            className="form-comments"
            placeholder="Questions? Thoughts? Well wishes? (optional)"
            value={toSend.comments}
            onChange={handleChange}
          />
          <div className="form-spinner">
            {isLoading ? <LoadingSpinner /> : null}
          </div>
          <button className="form-button" type="submit" disabled={isLoading}>
            Submit
          </button>
        </form>
      </div>
      <div className="message">{message ? <h2>{message}</h2> : null}</div>
    </>
  );
}

export default Form;
