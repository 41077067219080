import Form from "../Components/Form";
import rsvp from "../images/icons/rsvp.png";
import MovingComponent from "react-moving-text";

function Rsvp() {
  return (
    <main>
      <MovingComponent
        type="unfold"
        duration="2000ms"
        delay="0s"
        direction="normal"
        timing="ease"
        iteration="1"
        fillMode="none"
      >
        <h1>
          RSVP <img src={rsvp} className="icon" alt="rsvp icon" />
        </h1>
      </MovingComponent>
      <Form />
    </main>
  );
}

export default Rsvp;
