import registry from "../images/icons/gift.png";
import MovingComponent from "react-moving-text";

function Registry() {
  return (
    <>
      <main>
        <div>
          <h1>Registry</h1>
        </div>
        <MovingComponent
          type="shakeMix"
          duration="2000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <img src={registry} alt="gift icon" className="icon" />
        </MovingComponent>
        <div className="registry-link">
          {" "}
          <a
            href="https://www.zola.com/registry/laurenandjessejuly7"
            target="_blank"
            rel="noreferrer"
          >
            Here it is
          </a>
        </div>

        <div
          style={{
            width: "400px",
            margin: "auto",
            fontSize: "20px",
          }}
        >
          We've lived together for a little bit now, and we're fortunate enough
          to have a lot of nice stuff. We wouldn't mind an upgrade on some
          things, so we've chosen a few items here. We also would be especially
          grateful for contributions to our honeymoon/newlywed fund. Thank you
          all!
        </div>

        <div className="registry-poll-container"></div>
      </main>
    </>
  );
}

export default Registry;
