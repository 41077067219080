import "./Styles/Faq.css";
import { Link } from "react-router-dom";
import MovingComponent from "react-moving-text";
import skull from "../images/skull.GIF";
import directions1 from "../images/directions1.png";
import directions2 from "../images/directions2.png";

import { FaRegCopyright } from "react-icons/fa";

function Faq() {
  return (
    <>
      <main>
        <MovingComponent
          type="swing"
          duration="2000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <img src={skull} alt="skull gif" className="faq-skull-gif" />
        </MovingComponent>
        <h1>FAQ</h1>
        <div className="faq-questions">
          <p>So, it's in the Captain Bill's restaurant?</p>
          <p className="faq-answers">
            {" "}
            It's in the Bayview House which is on the same property as the
            restaurant. You'll find it!{" "}
          </p>
        </div>
        <div className="faq-questions">
          <p>Will there be a ceremony?</p>
          <p className="faq-answers">
            We had a small ceremony last year at City Hall. We're just having
            the party in July!{" "}
          </p>
        </div>
        <div className="faq-questions">
          <p>Can I bring the kids or a guest?</p>
          <p className="faq-answers">
            {" "}
            We ask that you only bring those listed on your invitation. We, of
            course believe "the more the merrier", but there are space
            limitations.{" "}
          </p>
        </div>
        <div className="faq-questions">
          <p>Do you have a registry?</p>
          <p className="faq-answers">
            Check it out <Link to="/registry">here!</Link>
          </p>
        </div>
        <div className="faq-questions">
          <p>What's the best way to get there?</p>
          <p className="faq-answers">
            There's plenty of parking at{" "}
            <a
              href="https://www.google.com/maps/place/Captain+Bills+Restaurant+%26+Catering/@40.7133929,-73.2416887,17z/data=!3m1!4b1!4m5!3m4!1s0x89e832ef611f17bb:0x502547580e427a68!8m2!3d40.7134755!4d-73.2415825"
              target="_blank"
              rel="noreferrer"
              alt="google maps link"
            >
              Captain Bill's
            </a>
            , so driving is a good option. NYC friends, you can take the LIRR to
            the Bay Shore station and hop in a cab or Uber/Lyft. It's only a 5
            minute drive from the station.
          </p>
          <p className="faq-answers">Here's the directions:</p>
          <img
            src={directions1}
            className="directions-image"
            alt="captain bills directions"
          />
          <img
            src={directions2}
            className="directions-image"
            alt="captain bills directions"
          />
        </div>
        <div className="faq-questions">
          <p>Do you have a hotel block?</p>
          <p className="faq-answers">
            Yes! It's at the{" "}
            <a
              href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1682518604646&key=GRP&app=resvlink"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Marriott Melville
            </a>{" "}
            We recommend booking as soon as you can if you know you're going to
            need accommodations. Just follow the link to get our group rate.
            Please book before June 5th!
          </p>
        </div>
        <div className="faq-questions">
          <p>How should I dress?</p>
          <p className="faq-answers">Cocktail attire or semi-formal is good!</p>
        </div>
        <div className="faq-questions">
          <p>Do I choose my meal preference?</p>
          <p className="faq-answers">
            We are doing it buffet style, so there will be a variety of food to
            choose from. Vegans, vegetarians, pescatarians, carnivores - we got you. Come hungry.
          </p>
        </div>
        <div className="faq-questions">
          <p>Will there be an after party?</p>
          <p className="faq-answers">
          If you're still standing and want to keep the party going, join us up the block at the <a href="https://www.google.com/maps/place/The+Penny+Pub/@40.7202685,-73.2494657,15z/data=!4m6!3m5!1s0x89e832f3ae9a4f13:0xe346995acae53cbb!8m2!3d40.7202685!4d-73.2494657!16s%2Fg%2F1tngr9_7?entry=ttu">Penny Pub.</a>
          </p>
        </div>
        <div className="faq-questions">
          <p>Are you Lauren "Newman" or "Powers"?</p>
          <p className="faq-answers">She's still Lauren Powers!</p>
        </div>
        <div className="faq-questions">
          <p>I have more questions.</p>
          <p className="faq-answers">
            Send us an{" "}
            <a href="mailto:laurenjesseweddingny@gmail.com">e-mail!</a>{" "}
            laurenjesseweddingny@gmail.com
          </p>
        </div>
      </main>
      <footer>
        <Link className="faq-about-this-page" to="/aboutthispage">
          {" "}
          <FaRegCopyright /> Lauren Powers 2023
        </Link>
      </footer>
    </>
  );
}

export default Faq;
