import bay from "../images/bayview.png";
import booze from "../images/booze.gif";
import MovingComponent from "react-moving-text";

function Location() {
  return (
    <>
      <main>
        <MovingComponent
          type="flip"
          duration="2000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <img src={booze} className="location-bottle-gif" alt="bottle gif" />
        </MovingComponent>
        <MovingComponent
          type="fadeInFromTop"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          {" "}
          <a href="https://captainbills.com/" target="_blank" rel="noreferrer">
            <img
              src={bay}
              className="location-bayview"
              alt="bayview house logo"
            />{" "}
          </a>
        </MovingComponent>

        <MovingComponent
          type="fadeInFromBottom"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <h3 className="location-date-time">
            Join us for cocktail hour, dinner, and dancing!
          </h3>
          <h3 className="location-date-time">Friday July 7th, 2023</h3>
          <h3 className="location-date-time">5:30PM</h3>
          <h3 className="location-date-time">
            <a
              href="https://www.google.com/maps/place/Captain+Bills+Restaurant+%26+Catering/@40.7133929,-73.2416887,17z/data=!3m1!4b1!4m5!3m4!1s0x89e832ef611f17bb:0x502547580e427a68!8m2!3d40.7134755!4d-73.2415825"
              target="_blank"
              rel="noreferrer"
            >
              122 Ocean Ave Bay Shore, NY 11706
            </a>
          </h3>
        </MovingComponent>
      </main>
    </>
  );
}

export default Location;
