import Gallery from "../Components/Gallery";
import { GiFilmStrip } from "react-icons/gi";

import addams from "../images/couplephotos/addams.PNG";
import beerys from "../images/couplephotos/beerys.jpeg";
import burns from "../images/couplephotos/burnsst.jpeg";
import bundy from "../images/couplephotos/bundy.JPG";
import canyon from "../images/couplephotos/canyon.jpeg";
import coldspring from "../images/couplephotos/coldspring.jpg";
import facetime from "../images/couplephotos/factime.PNG";
import first from "../images/couplephotos/firstpicture.jpg";
import friends1 from "../images/couplephotos/friends1.jpg";
import friends2 from "../images/couplephotos/friends2.jpg";
import hiking from "../images/couplephotos/hiking.jpg";
import hoh1 from "../images/couplephotos/hohrainforest.JPG";
import hotspring from "../images/couplephotos/hotspring.jpg";
import kramden from "../images/couplephotos/kramden.JPG";
import nelson from "../images/couplephotos/nelson.JPG";
import waterfall from "../images/couplephotos/waterfall.jpg";
import monteverde from "../images/couplephotos/monteverde.jpeg";
import mexico from "../images/couplephotos/mexico.JPG";
import mickey from "../images/couplephotos/mickey.JPG";
import olympicsign from "../images/couplephotos/olympicsign.jpeg";
import ouray from "../images/couplephotos/ouray.jpeg";
import pacificedge from "../images/couplephotos/pacificedge.jpeg";
import rialto from "../images/couplephotos/rialto.jpeg";
import summit from "../images/couplephotos/summit.jpeg";
import secondpic from "../images/couplephotos/secondpic.jpg";
import stamford from "../images/couplephotos/stamford.jpeg";
import stormking from "../images/couplephotos/stormking.jpeg";
import savage from "../images/couplephotos/savage.JPG";
import wedding from "../images/couplephotos/wedding.jpg";
import wedding2 from "../images/couplephotos/wedding2.jpg";

function Photos() {
  const galleryImages = [
    {
      img: `${first}`,
      caption: "Our first picture together",
    },
    {
      img: `${secondpic}`,
      caption: "And here's our second picture together",
    },
    {
      img: `${hotspring}`,
      caption: "Hot springs in La Fortuna",
    },
    {
      img: `${nelson}`,
    },
    {
      img: `${waterfall}`,
      caption: "Swimming hole in Uvita, Costa Rica",
    },
    {
      img: `${hiking}`,
      caption: "Hiking in the Adirondacks during COVID-19 shutdown",
    },
    {
      img: `${canyon}`,
      caption: "Black Canyon of the Gunnison National Park, Colorado",
    },
    {
      img: `${friends1}`,
      caption: "With the crew that made it all happen",
    },
    {
      img: `${coldspring}`,
      caption: "Hiking near Breakneck Ridge, NY",
    },
    {
      img: `${mexico}`,
      caption: "Thermal cave in Hidalgo, Mexico",
    },
    {
      img: `${beerys}`,
      caption: "Hangin' at Mr. Beery's",
    },
    {
      img: `${burns}`,
      caption: "On our way to a friend's wedding",
    },
    {
      img: `${wedding}`,
      caption: "City Hall wedding day outside our apartment building",
    },

    {
      img: `${savage}`,
      caption: "Another great shot from our wedding day",
    },
    {
      img: `${wedding2}`,
      caption: "City Hall wedding day in Forest Hills Gardens",
    },
    {
      img: `${rialto}`,
      caption: "Rialto Beach in Olympic National Park, Washington State",
    },
    {
      img: `${friends2}`,
      caption: "Hanging at the Beaster show",
    },
    {
      img: `${monteverde}`,
      caption: "Monteverde Cloud Forest",
    },
    {
      img: `${stamford}`,
      caption: "At a friend's wedding in Stamford, CT",
    },
    {
      img: `${stormking}`,
      caption:
        "Mt. Storm King, Washington State. Before we found out All Trails lied and listed it as a 'moderate' hike",
    },
    {
      img: `${ouray}`,
      caption: "A cool overlook in Ouray, Colorado",
    },

    {
      img: `${bundy}`,
      caption:
        "Love and marriage, love and marriage. Go together like a horse and carriage",
    },

    {
      img: `${summit}`,
      caption:
        "Summit to Mt. Storm King, Lake Crescent in background, Washington State",
    },

    {
      img: `${olympicsign}`,
      caption: "Olympic National Park - Hoh Rainforest",
    },
    {
      img: `${mickey}`,
      caption: "We love a good road trip.",
    },

    {
      img: `${facetime}`,
      caption: "Facetime during pandemic",
    },

    {
      img: `${kramden}`,
      caption: "Bang Zoom ...straight to the moon!",
    },

    {
      img: `${pacificedge}`,
      caption: "Dominical, Costa Rica. One of our favorite places ever",
    },
    {
      img: `${hoh1}`,
      caption: "Camping around the Pacific Northwest",
    },

    {
      img: `${addams}`,
    },
  ];

  return (
    <>
      <main>
        <h1>
          <GiFilmStrip />
          Photos
          <GiFilmStrip />
        </h1>
        <Gallery galleryImages={galleryImages} />
      </main>
    </>
  );
}

export default Photos;
