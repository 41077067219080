function Travel() {
  return (
    <>
      <main>
        <h1>Travel & Stay</h1>
        <p>Here are some hotels local to Captain Bill's</p>
        <ul className="travel-stay-list">
          <li>
            {" "}
            <a
              href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1682518604646&key=GRP&app=resvlink"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Marriott Melville
            </a>
          </li>
          <h2>We've got some rooms blocked off at the Marriott. </h2>
          <h2>Just follow the link above to book and get our group rate. </h2>
          <h2>Please book before June 3rd!</h2>
          <li>
            {" "}
            <a
              href="https://www.hilton.com/en/hotels/comnyhx-hampton-long-island-commack/"
              target="_blank"
              rel="noreferrer"
            >
              Hampton Inn Commack
            </a>
          </li>
          <li>
            <a
              href="https://www.hilton.com/en/hotels/isphhhf-hilton-long-island-huntington/"
              target="_blank"
              rel="noreferrer"
            >
              Huntington Hilton
            </a>
          </li>
          <li>
            <a
              href="https://www.marriott.com/en-us/hotels/nycpf-four-points-by-sheraton-melville-long-island/overview/"
              target="_blank"
              rel="noreferrer"
            >
              Four Points Melville
            </a>
          </li>
        </ul>
      </main>
    </>
  );
}

export default Travel;
